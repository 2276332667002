$layout-name: "l-footer";

.#{$layout-name} {
  background-color: $b2-color;
  box-shadow: rem(6) rem(4) rem(10) $shadow-color;
  color: #fff;
  margin: auto 0 0 0;
  padding: rem(15) 0 0 0;

  * {
    color: #fff;
  }
}
