$layout-name: "l-header";

.#{$layout-name} {

  position: relative;
  min-height: 8vmax;

  &::before {
    background-color: $b2-color;
    bottom: 0;
    box-shadow: rem(6) rem(4) rem(10) $shadow-color;
    content: '';
    height: 200%;
    position: absolute;
    right: 0;
    transform-origin: right;
    transform: skewY(5deg);
    width: 100%;
    z-index: z-index(default);
  }

  &::after {
    background-color: $b1-color;
    bottom: 0;
    box-shadow: rem(-6) rem(4) rem(10) $shadow-color;
    content: '';
    height: 200%;
    left: 0;
    position: absolute;
    transform-origin: left;
    transform: skewY(-4deg);
    width: 100%;
    z-index: z-index(default);
  }

  &__container {
    position: relative;
    z-index: z-index(above);
  }

  &__logo {
    color: $header-text-color;
    display: inline-block;
    text-decoration: none;
  }
}
