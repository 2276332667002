$component-name: "c-calculator";

.#{$component-name} {
  background-color: #fff;
  border-radius: rem(10);
  box-shadow:
    rem(-6) rem(-6) rem(26) 0 #fff,
    rem(6) rem(6) rem(16) 0 rgba($color: #666, $alpha: .3)
  ;
  margin: rem(20) 0 !important;
  padding: rem(20) !important;

  &__row {
    margin-bottom: rem(15) !important;
    &:last-child {
      margin-bottom: 0 !important;
    }
  }

  &__control {
    display: block;
    font-size: rem(14);
    color: $input-label-color;
    text-align: center;
  }

  &__input {
    border: none;
    font-size: rem(36);
    color: $input-color;
    width: 100%;
    text-align: center;
    font-weight: bold;
    border-bottom: rem(1) solid transparent;

    &:focus {
      border-bottom: rem(1) solid $input-color;
    }
  }

  &__divider {
    color: $input-label-color;
    font-size: rem(20);
    font-weight: bold;
    text-align: center;
  }
}
