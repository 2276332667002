// ==========================================================================
// Colors
// ==========================================================================

// webfatorial
// ==========================================================================

$webfatorial-colors: (
  "webfatorial-primary": #157bad,
  "webfatorial-secondary": #06618c,
  "webfatorial-success": #3cc368,
  "webfatorial-warning": #f5a600,
  "webfatorial-error": #ec5840,
  "webfatorial-gray-1": #363636,
  "webfatorial-gray-2": #4f4f4f,
  "webfatorial-gray-3": #828282,
  "webfatorial-gray-4": #bdbdbd,
  "webfatorial-gray-5": #e0e0e0,
  "webfatorial-white": #fcfcfc,
);

// dpw
// ==========================================================================

$dpw-colors: (
  "dpw-primary": #3da6c8,
  "dpw-secondary": darken(#3da6c8, 12%),
);

// Social Media
// ==========================================================================

$social-media-colors: (
  "facebook": #3b5998,
  "twitter": #00aced,
  "linkedin": #0077b5,
  "youtube": #cd201f,
  "instagram": #e4405f,
  "github": #333,
);

// ==========================================================================
// Measures
// ==========================================================================

$global-max-width-raw: 1200;
$global-max-width: rem($global-max-width-raw);

$global-font-size: rem(18);
$global-line-height: 1.5;
$global-radius: rem(6);
$global-module-size: rem(30);

// ==========================================================================
// Transitions
// ==========================================================================

$transition-duration: 305ms;
$transition-duration-complex: 385ms;
$transition-duration-entrance: 235ms;
$transition-duration-exit: 205ms;
$transition-duration-fast: 150ms;

$transition-function-ease-in-expo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$transition-function-ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
$transition-function-ease-in-back: cubic-bezier(0.6, -0.28, 0.735, 0.045);
$transition-function-ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$transition-function-ease-in-out-circ: cubic-bezier(0.785, 0.135, 0.15, 0.86);
$transition-function-ease-in-out-quint: cubic-bezier(0.86, 0, 0.07, 1);
$transition-function-elastic: cubic-bezier(0.175, 0.885, 0.41, 1.46);

// ==========================================================================
// Others
// ==========================================================================

$z-indexes: (
  "above": 500,
  "modal": 400,
  "overlay": 300,
  "dropdown": 200,
  "default": 1,
  "below": -1,
);

// ==========================================================================
// Project definitions
// ==========================================================================

$b1-color: map-get($dpw-colors, "dpw-primary");
$b2-color: map-get($dpw-colors, "dpw-secondary");

$body-bg-color: #f5f5f5;
$shadow-color: #333;
$header-text-color: #fff;
$input-label-color: #333;
$input-color: $b1-color;

// $b1-color: #f3a56f;
// $b2-color: #ec772b;
// $body-bg-color: #f5f5f5;

// $shadow-color: #9f4c1b;
// $header-text-color: #9f4c1b;
