*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  -webkit-font-smoothing: antialiased;

  @media only screen and (-webkit-min-device-pixel-ratio: 1.25),
    only screen and (min-device-pixel-ratio: 1.25),
    only screen and (min-resolution: 200dpi),
    only screen and (min-resolution: 1.25dppx) {
    -webkit-font-smoothing: subpixel-antialiased;
  }
}

*:focus {
  outline: none;
}

img,
iframe,
object,
embed,
video {
  height: auto;
  max-width: 100%;
}

object,
embed,
video {
  height: auto;
}

figure {
  margin: 0;
}

label {
  cursor: pointer;
}

sup,
sub {
  font-size: 50%;
}

// ==========================================================================
// Project specifics
// ==========================================================================

body {
  background-color: $body-bg-color;
  display: flex;
  flex-direction: column;
  font-family: Nunito, sans-serif;
  line-height: 1.35;
  font-size: $global-font-size;
  min-height: 100vh;
}

h2 {
  font-size: rem(22);
}

code {
  background-color: #f7f7f9;
  border-radius: 0.1875rem;
  border: 0.0625rem solid #e0e0e0;
  color: #e4405f;
  padding: 0 rem(5);
  word-break: break-word;
}

// ::selection {
// }

// ::-moz-selection {
// }

// input::placeholder {}
// textarea::placeholder {}
// input::-webkit-input-placeholder {}
// textarea::-webkit-input-placeholder {}
// input::-moz-placeholder {}
// textarea::-moz-placeholder {}
// input:-ms-input-placeholder {}
// textarea:-ms-input-placeholder {}
