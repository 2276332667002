$component-name: "c-preview";

:root {
  --preview-width: 240px;
  --preview-height: 135px;
}

.#{$component-name} {
  display: flex;
  justify-content: center;
  margin-top: rem(30);

  &__canvas {
    background-color: $b2-color;
    box-shadow: rem(6) rem(6) rem(16) 0 rgba($color: #666, $alpha: 0.3);
    display: inline-block;
    height: var(--preview-height);
    width: var(--preview-width);
    position: relative;
    font-size: rem(14);

    &::before {
      content: attr(data-pixels-width) "px";
      left: 50%;
      position: absolute;
      top: rem(-25);
      transform: translateX(-50%);
      width: 100%;
      min-width: rem(120);
      text-align: center;
    }

    &::after {
      content: attr(data-pixels-height) "px";
      position: absolute;
      right: rem(-8);
      top: 50%;
      transform: translate(100%, -50%);
    }
  }
}
